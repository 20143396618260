// VIP_KUAIPK_20240617
// 单个类别条件数量限制(condition:create:single:num:limit)：99999
// 全条件数量限制(condition:create:all:num:limit)：99999
// 导入课表次数限制(scheme:import:num:limit)：99999
// 方案列表数量限制(scheme:create:num:limit)：99999
// 导出课表(timetable:export)
// 行政班课表导出次数限制(timetable:export:num:limit)：99999
// 行政班课表导出内容完全开放(timetable:export:detail:open)
// 学生选科人数限制(ncee:choose:subject:limit)：20
// 新高考课表导出次数限制(ncee:schedule:count:export)：1

// CLASS_SCHOOL_VIP
// 课时统计(course-hour:statistics)
// 调代课功能(course-substitute)
// 单个类别条件数量限制(condition:create:single:num:limit)：99999
// 全条件数量限制(condition:create:all:num:limit)：99999
// 导入课表次数限制(scheme:import:num:limit)：99999
// 方案列表数量限制(scheme:create:num:limit)：99999
// 导出课表(timetable:export)
// 行政班课表导出次数限制(timetable:export:num:limit)：99999
// 小程序发布课表(applet:timetable:publish)
// 行政班课表导出内容完全开放(timetable:export:detail:open)
// 行政班方案邀请协同(scheme:invite:collaboration)

// NEW_CLASS_SCHOOL_VIP
// 课时统计(course-hour:statistics)
// 调代课功能(course-substitute)
// 单个类别条件数量限制(condition:create:single:num:limit)：99999
// 全条件数量限制(condition:create:all:num:limit)：99999
// 导入课表次数限制(scheme:import:num:limit)：99999
// 方案列表数量限制(scheme:create:num:limit)：99999
// 导出课表(timetable:export)
// 行政班课表导出次数限制(timetable:export:num:limit)：99999
// 小程序发布课表(applet:timetable:publish)
// 行政班课表导出内容完全开放(timetable:export:detail:open)
// 学生选科人数限制(ncee:choose:subject:limit)：999999
// 导出分班结果(ncee:export:divide:class:result)
// 分班结果数据无遮罩(ncee:divide:class:result:data)
// 导出开班计划(ncee:start:class:plan:export)
// 生成开班计划的结果无遮罩(ncee:start:class:plan:create)
// 课表内容全部导出(ncee:schedule:content:export)
// 新高考课表导出次数限制(ncee:schedule:count:export)：99999
// 课表预览无遮罩(ncee:schedule:view)
// 新高考方案邀请协同(ncee:scheme:invite:collaboration)
// 行政班方案邀请协同(scheme:invite:collaboration)
export default {
  VIP_KUAIPK_20240617: [
    "condition:create:single:num:limit",
    "condition:create:all:num:limit",
    "scheme:import:num:limit",
    "scheme:create:num:limit",
    "timetable:export",
    "timetable:export:num:limit",
    "timetable:export:detail:open",
    "ncee:choose:subject:limit",
    "ncee:schedule:count:export",
  ],
  CLASS_SCHOOL_VIP: [
    "course-hour:statistics",
    "course-substitute",
    "condition:create:single:num:limit",
    "condition:create:all:num:limit",
    "scheme:import:num:limit",
    "scheme:create:num:limit",
    "timetable:export",
    "timetable:export:num:limit",
    "applet:timetable:publish",
    "timetable:export:detail:open",
    "scheme:invite:collaboration",
    "supervision:exam",
  ],
  NEW_CLASS_SCHOOL_VIP: [
    "course-hour:statistics",
    "course-substitute",
    "condition:create:single:num:limit",
    "condition:create:all:num:limit",
    "scheme:import:num:limit",
    "scheme:create:num:limit",
    "timetable:export",
    "timetable:export:num:limit",
    "applet:timetable:publish",
    "timetable:export:detail:open",
    "ncee:choose:subject:limit",
    "ncee:export:divide:class:result",
    "ncee:divide:class:result:data",
    "ncee:start:class:plan:export",
    "ncee:start:class:plan:create",
    "ncee:schedule:content:export",
    "ncee:schedule:count:export",
    "ncee:schedule:view",
    "ncee:scheme:invite:collaboration",
    "scheme:invite:collaboration",
    "supervision:exam",
    "scheme:share",
  ],
  INS_VIP_KUAIPK: ["training:scheduling"],
};
