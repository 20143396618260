import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createValidateStatus, setAuthHeaders } from "@/utils/servicesUtils";
import { SERVER_URL } from "@/config/constants";
import { setFeedbackState } from "@/store/feedback";

const base = createApi({
  tagTypes: [
    "getUserInfo", // 获取用户信息
    "getUserVipInfo", // 获取用户vip信息
    "teachingBuildingList", // 教学楼列表
    "scheduleRuleList", // 规则列表
    "scheduleSheetData", // 课时任课数据
    "adjustSchedule",
    "adjustAddSchedule",
    "adjustRemoveSchedule",
    "autoArrange",
    "clearSchedule",
    "lock",
    "scheduleList", // 方案列表
    "scheduleInfo", // 方案详情
    "gradeClassList", // 年级班级列表
    "gradeBySchemaId", // 当前方案的年级
    "uniqCourseNames", // 课程名列表
    "timeTable", // 设置条件课表
    "remainCourseClassCount", // 手动调整 - 课程班级剩余的课时
    "getOddAndDoubleBindInfoByGrade",
    "AdjustExchangeList",
    "overlapped",
    "userInfo",
    "orderList",
    // "abbrConfig",
    "schemaTableInfo", // 录入信息 - 作息时间表
    "getCourseClassesWithGrade",
    "getCourseClassesWithCourse",
    "conflicts",
    "getStatWeightConfig",
    "getCourseHourStat",
    "getSelectSubjectTaskList",
    "getSelectSubjectTaskDetails",
    "getSelectSubjectTaskSubjects",
    "getDivideClassTaskList",
    "getDivideClassTaskDetails", // 获取分班方案配置详情
    "getDivideClassResultData", // 获取分班结果详情
    "getSelectSubjectList", // 获取选科结果详情
    "getSubjectListForUpdateStudentSubject",
    "getArrangeInvigilationList",
    "getArrangeInvigilationRuleDetails",
    "getTeacherList", // 获取教师列表
    "getExamSettingDetails", // 获取任务设置详情
    "getArrangeInvigilationDetails",
    "getArrangeInvigilationDetailsByDragTeacher",
    "getArrangeInvigilationStatistics",
    "getArrangeInvigilationTeacherList", // 获取教师列表
    "getClassScheduleTaskList", // 获取开班计划列表
    "getLessonPlanClassScheduleTaskContent", // 获取开班计划参数详情
    "getClassScheduleTaskSetting", // 获取开班结果设置详情
    "getClassScheduleResult",

    // 走班排课
    "getOptionalCourseSchedulingList",
    "getOCSInputInformation",
    "getOCSTimeTableDetails",
    "getOCSCourseSheetData",
    "getOCSCourseGroupList",
    "getOCSCourseGroupSubjectList",

    "getGroupInvitationInfo",
    "getTeamList",
    "getMemberList",
    "getCooperateList", // 获取团队协作列表

    // 新版排课
    "getCourseScheduleList",
    "getCourseScheduleBasicInfo",
    "getScheduleGradeList",
    "getScheduleTeachingInfoByGrade",
    "getImportArrangeTeachingHistoryList",
    "getOddAndDoubleCourseBoundInfo",
    "getSameClassroomGroupList",
    "getSameClassroomGroupCourseList",
    "getScheduleRulesByType",
    "getScheduleCourseTree",
    "getScheduleTeacherTree",
    "getScheduleCourseClassTree",
    "getClassScheduleData", // 获取调课班级课表数据
    "getTeacherScheduleData", // 获取调课教师课表数据
    "getGradeTeacherTree", // 获取年级科目教师树
    "getGradeClassTree", // 获取年级班级树
    "getConflictsCheckData", // 获取冲突检查数据
    "getAdjustSubstituteClassTimeTable",
    "getAdjustSubstituteTeacherTimeTable",
    "getAdjustSubstituteInfo",
    "getAbbrConfigList", //获取简称配置
    "getTrainingClassCourseAndStudentList",
    "getTrainingClassCourseTeacherList",
    "getTrainingClassCourseTeacherHourSetting",
    "getSignInList",
    "getPublicClassRoomList",
    "getMergeCourseList",
    "getLayerCourseList",
    "getArrangeProgress", // 获取排课进度
    "getRulePriorityList",
    "getSubjectTeacherRules",
    "previewExportGetTemplateList", // 预览导出模板列表
    "getScheduleRulesSatisfaction",
    "getExchangeWeekConfig",
    "getExchangeScheduleList",
    "getContinuousClassList",
  ],
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    prepareHeaders: (headers) => {
      setAuthHeaders(headers);
      return headers;
    },
    fetchFn: (input, init) => {
      return fetch(input, init).catch((error) => {
        // 网络故障或者请求被阻止时触发
        setFeedbackState({
          globalMessageError: {
            level: "error",
            message: "请求失败，请稍后重试！",
          },
        });
        console.error("Request Error: 网络故障或者请求被阻止!", error);
        throw error;
      });
    },
    validateStatus: createValidateStatus(),
  }),
  endpoints: () => ({}),
});

export default base;
