import { useAppSelector } from "@/store";
import { useMemo } from "react";
import { IVipRoleType } from "@/services/user";

export function getVipType(vipList?: { tag: IVipRoleType; vip: boolean }[]) {
  const isExVIP = vipList?.some((item) => item.tag === "EX_VIP_KUAIPK" && item.vip);
  const isXzbVIP = vipList?.some((item) => item.tag === "CLASS_VIP" && item.vip);
  const isXzbVIP20240617 = vipList?.some((item) => item.tag === "VIP_KUAIPK_20240617" && item.vip);
  const isXzbSchoolVIP = vipList?.some((item) => item.tag === "CLASS_SCHOOL_VIP" && item.vip);
  const isXgkSchoolVIP = vipList?.some((item) => item.tag === "NEW_CLASS_SCHOOL_VIP" && item.vip);
  const isInsVIP = vipList?.some((item) => item.tag === "INS_VIP_KUAIPK" && item.vip);

  const isPersonalVIP = isExVIP || isXzbVIP || isXzbVIP20240617;
  const isSchoolVIP = isXzbSchoolVIP || isXgkSchoolVIP;

  return {
    isExVIP,
    isXzbVIP,
    isXzbVIP20240617,
    isXzbSchoolVIP,
    isXgkSchoolVIP,
    isPersonalVIP,
    isSchoolVIP,
    isInsVIP,
  };
}

export default function useVipType() {
  const { userVipInfo } = useAppSelector((state) => ({
    userVipInfo: state.user.userVipInfo,
  }));

  return useMemo(() => {
    return getVipType(userVipInfo?.vipList);
  }, [JSON.stringify(userVipInfo)]);
}
